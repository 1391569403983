import React from 'react'
import { APP_CONFIG } from '../../config/app'

export const SocialMediaProviderButton = ({ providerHref, icon, label }) => (
  <a className="button btn btn-lg btn-social w-100 fw-bolder" href={`${APP_CONFIG.SERVER_URI}${providerHref}`}>
    <div className="btn-social-content">
      {icon}
      <div style={{ flexGrow: '1', display: 'flex', justifyContent: 'center' }}>
        <strong>{label}</strong>
      </div>
    </div>
  </a>
)
