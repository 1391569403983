import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

function InputEmail (props) {
  const [email, setEmail] = useState('')
  const [valid, setValid] = useState(false)

  useEffect(() => {
    props.setEmail(email)
    props.setValidEmail(valid)
  }, [email, valid])

  const handleChange = e => {
    setValid(validateEmail(e.target.value))
    setEmail(e.target.value.toLowerCase())
  }

  const validateEmail = email => /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    .test(email)

  return (
    <div className="mt-0 mb-2 input-container">
      <label htmlFor="email" className="form-label">{props.label}</label>
      <div className="input-with-icon">
        <input
          type="email"
          name="email"
          id="email"
          placeholder={props.placeholder || null}
          className={`form-control input-login ${email === '' ? '' : valid ? 'input-success' : 'input-error'}`}
          onChange={handleChange} />
        <i className={`fa icon fa-check text-success ${valid ? '' : 'd-none'}`}/>
      </div>
    </div>
  )
}

InputEmail.propTypes = {
  label: PropTypes.string,
  setEmail: PropTypes.func,
  setValidEmail: PropTypes.func,
  placeholder: PropTypes.string
}

export default InputEmail
