import InputEmail from './InputEmail'
import InputPassword from './InputPassword'
import ErrorAlert from './ErrorAlert'
import React from 'react'
import { CARD_BODY_COLUMN_DESCRIPTION } from '../../helpers/card-body-column-description'
import SuccessAlert from './SuccessAlert'
import { MailLabel } from './mail-label'

export const LoginForm = ({
  submit,
  error,
  setPassword,
  setValidPassword,
  setEmail,
  setValidEmail,
  clearError,
  success,
  setSuccess,
  verifiedEmail,
  verifyEmail,
  email
}) => (
  <>
    <div className="row justify-content-center">
      <form
        onSubmit={event => {
          event.preventDefault()
          if (verifiedEmail) return submit().then()
          verifyEmail()
        }}
        className={CARD_BODY_COLUMN_DESCRIPTION}>
        {error &&
          <div className="row mt-1">
            <div className="col">
              <ErrorAlert
                onCloseAlert={clearError}
                message={error.message} />
            </div>
          </div>
        }
        {success &&
          <SuccessAlert
            onCloseAlert={() => setSuccess(null)}
            message={success} />
        }
        <p className='mt-1 mb-3 text-center login-description'>
          Ahora puedes acceder a <strong>HEB Mx</strong> y a{' '}
          <strong>Mi Tienda del Ahorro</strong> desde tu misma cuenta.
        </p>
        {verifiedEmail
          ? <MailLabel authenticationStrategy='email' email={email}/>
          : <InputEmail
            placeholder="Correo electrónico"
            setEmail={setEmail}
            setValidEmail={setValidEmail} />
        }
        {verifiedEmail && <InputPassword
          email={email}
          placeholder="Contraseña mínimo de 8 caracteres"
          label={'Contraseña'}
          setPassword={setPassword}
          setValidPassword={setValidPassword}
          showRecover={true} />
        }
        <button type="submit" value="Submit" hidden/>
      </form>
    </div>
  </>
)
