import React from 'react'
import { CARD_BODY_COLUMN_DESCRIPTION } from '../../helpers/card-body-column-description'

export const Disclaimer = ({ isMiTienda, initialMessage = 'Al crear una cuenta' }) => (
  <div className={`${CARD_BODY_COLUMN_DESCRIPTION} mt-3`}>
    <p className="disclaimer small-print text-center">
      {initialMessage}, aceptas el
      <a href={privacyUrlByClient[isMiTienda ? 'miTienda' : 'HEB']} target='_blank' rel="noreferrer" style={{ textDecoration: 'none' }}><strong className="disclaimer-underline"> Aviso de Privacidad </strong></a>
      y los
      <a href={termsAndConditionsUrlByClient[isMiTienda ? 'miTienda' : 'HEB']} target='_blank' rel="noreferrer" style={{ textDecoration: 'none' }}><strong className="disclaimer-underline"> Términos y Condiciones</strong></a>
      , así como el envío de noticias y promociones exclusivas de HEB y Mi Tienda del Ahorro.
    </p>
  </div>
)

const termsAndConditionsUrlByClient = {
  miTienda: 'https://www.mitienda.mx/terminos-y-condiciones',
  HEB: 'https://www.heb.com.mx/tyc-promociones'
}

const privacyUrlByClient = {
  miTienda: 'https://www.mitienda.mx/aviso-privacidad',
  HEB: 'https://www.heb.com.mx/aviso-privacidad'
}
