export const MailLabel = ({ style, email, authenticationStrategy = 'email' }) => {
  const iconFileNamePerStrategy = {
    facebook: 'facebook-icon.svg',
    apple: 'apple-icon.svg',
    google: 'google-icon.svg'
  }

  const iconFileName = iconFileNamePerStrategy[authenticationStrategy]

  const iconElement = authenticationStrategy === 'email'
    ? <i style={{ display: 'flex', alignItems: 'center' }} className="fa-regular fa-envelope"></i>
    : <div style={{ display: 'flex', alignItems: 'center' }}><img width={24} height={24} src={`${process.env.PUBLIC_URL}/assets/auth-strategies/${iconFileName}`}/></div>
  return (
    <div style={{ ...wrapperStyle, ...style }}>
      {iconElement}
      <span style={{ textOverflow: 'ellipsis' }}>{email}</span>
    </div>
  )
}

const wrapperStyle = {
  display: 'flex',
  fontSize: '16px',
  color: '#717172',
  padding: '8px 16px',
  borderRadius: '40px',
  backgroundColor: '#F1F5F9',
  gap: '28px',
  overflowX: 'hidden',
  marginBottom: '20px'
}
